export default {
    root: {
        width: '2rem',
        height: '2rem',
        fontSize: '1rem',
        background: '{content.hover.background}',
        borderRadius: '{content.border.radius}'
    },
    group: {
        borderColor: '{content.background}',
        offset: '-1rem'
    },
    lg: {
        width: '3rem',
        height: '3rem',
        fontSize: '1.5rem'
    },
    xl: {
        width: '4rem',
        height: '4rem',
        fontSize: '2rem'
    }
};
